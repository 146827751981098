import React from 'react'

import { Link } from "react-scroll";
import { FaRegEnvelope, FaGithub, FaArrowCircleDown, FaLinkedin } from "react-icons/fa";
const Hero = () => {
    return (
        <div id="hero" className='flex flex-col h-screen mx-auto justify-center items-center bg-[#1b1b1b]'>
            <div className='text-white font-bold text-5xl md:text-7xl'>Hi, I'm <span className='text-[#9542f5]'>Chi Li</span>.</div>
            <div className="text-white font-semibold text-3xl mt-2">Software Engineer</div>

            <ul className='flex flex-row py-10 gap-5'>
                <li><a href="https://github.com/chishingli" target="_blank" rel="noopener noreferrer"><FaGithub className="hover:fill-[#C897F3]" size={50} fill="white" /></a></li>
                <li><a href="https://linkedin.com/in/chishingli" target="_blank" rel="noopener noreferrer"><FaLinkedin className="hover:fill-[#C897F3]" size={50} fill="white" /></a></li>
                {/* <li><FaRegEnvelope className="hover:fill-[#C897F3]" size={50} fill="white" /></li> */}
            </ul>
            <div className="pt-28">
                <Link to="about" smooth={true} duration={500}><FaArrowCircleDown className="mt-10 hover:translate-y-8 duration-500" fill="#C897F3" size={35} /></Link>
            </div>
        </div>
    )
}

export default Hero