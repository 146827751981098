import React, { useState } from 'react'
import logo from "../assets/logo.svg";
import { IoMenuSharp, IoCloseSharp } from "react-icons/io5";
import { Link } from "react-scroll";

const Navbar = () => {
    const [navMenuOpen, setNavMenuOpen] = useState(false);
    const handleMenuClick = () => {
        setNavMenuOpen(!navMenuOpen);
    }
    return (
        <div className="fixed flex justify-between items-center h-[80px] w-full mx-auto px-10 backdrop-blur-sm">
            <div className="w-full text-3xl font-bold mt-5">
                <Link to="hero" smooth={true} duration={500}>
                    <img src={logo} className="h-[75px] p-2" alt="logo" />
                </Link>
            </div>
            <ul className='hidden flex-row flex-end text-white font-bold md:flex'>
                <li className='p-4 hover:text-[#C897F3]'><Link to="about" smooth={true} duration={500} offset={-100} onClick={handleMenuClick}>About</Link></li>
                <li className='p-4 hover:text-[#C897F3]'><Link to="skills" smooth={true} duration={500} offset={-100} onClick={handleMenuClick}>Skills</Link></li>
                <li className='p-4 hover:text-[#C897F3]'><Link to="projects" smooth={true} duration={500} offset={-100} onClick={handleMenuClick}>Projects</Link></li>
                <li className='p-4 hover:text-[#C897F3]'><Link to="contact" smooth={true} duration={500} onClick={handleMenuClick}>Contact</Link></li>
            </ul>

            <div className="z-20 md:hidden" onClick={handleMenuClick}>
                {navMenuOpen === false ? <IoMenuSharp size={50} fill="white" /> : <IoCloseSharp size={50} fill="white" />}
            </div>

            {/* Mobile menu */}
            <div className={navMenuOpen === true ? "z-10 absolute top-0 left-0 flex flex-col justify-center items-center w-screen h-screen bg-[#7b609f] md:hidden" : "hidden"}>
                <ul className="flex flex-col items-center text-white text-2xl font-bold md:hidden">
                    <li className='p-4 hover:text-[#C897F3] border-b-2  border-b-gray-900'><Link to="about" smooth={true} offset={-100} duration={500} onClick={handleMenuClick}>About</Link></li>
                    <li className='p-4 hover:text-[#C897F3] border-b-2 border-b-gray-900'><Link to="skills" smooth={true} offset={-100} duration={500} onClick={handleMenuClick}>Skills</Link></li>
                    <li className='p-4 hover:text-[#C897F3] border-b-2 border-b-gray-900'><Link to="projects" smooth={true} offset={-100} duration={500} onClick={handleMenuClick}>Projects</Link></li>
                    <li className='p-4 hover:text-[#C897F3]'><Link to="contact" smooth={true} duration={500} onClick={handleMenuClick}>Contact</Link></li>
                </ul>
            </div>
        </div>
    )
}

export default Navbar