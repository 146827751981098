import React from 'react'

import ReactIcon from '../assets/react.png'
import NodeJs from '../assets/node.png'
import MongoDB from '../assets/mongo.png'
import HTML from '../assets/html.png'
import CSS from '../assets/css.png'
import Java from '../assets/java.png'
import Javascript from '../assets/javascript.png'
const Skill = () => {
    return (
        <div id="skills" className="flex flex-col p-4 h-screen w-screen bg-[#1b1b1b]">
            <div className='flex flex-col justify-center items-center w-full h-full'>
                <h1 className="text-white text-5xl font-bold">Skills</h1>
                <div className="block border-b-4 border-y-[#9542f5] mt-5 w-20" />
                <h2 className='text-white my-4 text-xl text-center'>Here are some of the technologies that I worked with recently.</h2>
                <div class='max-w-[1000px] grid grid-cols-2 justify-center items-center gap-10 md:grid-cols-3'>
                    <div className='flex flex-col justify-center items-center'>
                        <div>
                            <img className='w-[100px] h-[100px] hover:scale-110 duration-300' src={Java} alt="Java Icon" />
                        </div>
                        <div className='text-white font-bold text-lg'>Java</div>
                    </div>
                    <div className='flex flex-col justify-center items-center'>
                        <div>
                            <img className='w-[100px] h-[100px] hover:scale-110 duration-300' src={ReactIcon} alt="React Icon" />
                        </div>
                        <div className='text-white font-bold text-lg'>React</div>
                    </div>
                    <div className='flex flex-col justify-center items-center'>
                        <div>
                            <img className='w-[100px] h-[100px] hover:scale-110 duration-300' src={NodeJs} alt="NodeJS Icon" />
                        </div>
                        <div className='text-white font-bold text-lg'>NodeJS</div>
                    </div>
                    <div className='flex flex-col justify-center items-center'>
                        <div>
                            <img className='w-[100px] h-[100px] hover:scale-110 duration-300' src={Javascript} alt="JavaScript Icon" />
                        </div>
                        <div className='text-white font-bold text-lg'>JavaScript</div>
                    </div>
                    <div className='flex flex-col justify-center items-center'>
                        <div>
                            <img className='w-[100px] h-[100px] hover:scale-110 duration-300' src={HTML} alt="HTML Icon" />
                        </div>
                        <div className='text-white font-bold text-lg'>HTML</div>
                    </div>
                    <div className='flex flex-col justify-center items-center'>
                        <div>
                            <img className='w-[100px] h-[100px] hover:scale-110 duration-300' src={CSS} alt="CSS Icon" />
                        </div>
                        <div className='text-white font-bold text-lg'>CSS</div>
                    </div>
                    <div className='flex flex-col justify-center items-center'>
                        <div>
                            <img className='w-[100px] h-[100px] hover:scale-110 duration-300' src={MongoDB} alt="MongoDB Icon" />
                        </div>
                        <div className='text-white font-bold text-lg'>MongoDB</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skill