import React from 'react'
import { FaRegEnvelope, FaGithub, FaArrowCircleDown, FaLinkedin } from "react-icons/fa";
import { IoSendSharp } from "react-icons/io5";

const Contact = () => {
    return (
        <div id="contact" className="flex flex-col justify-center items-center p-4 h-screen w-screen bg-[#1b1b1b] mt-20">
            <div className='flex flex-col h-full w-full justify-center items-center mx-8'>
                <h1 className="text-white text-5xl font-semibold">Contact</h1>
                <div className="block border-b-4 border-y-[#9542f5] mt-5 w-20" />
                <div className='w-full md:w-[30%]'>
                    <form className="flex flex-col gap-8 mt-8" name="portfolio-contact-form" method="POST">
                        <input type="hidden" name="form-name" value="portfolio-contact-form" />
                        <div hidden><input name="bot-field" /></div>
                        <input type="text" id="name" name="name" className="rounded h-8 px-[4px]" placeholder='Name' required />
                        <input type="text" id="email" name="email" className="rounded h-8 px-[4px]" placeholder='Email' required />
                        <textarea name="message" id="message" className="rounded px-[4px]" cols="30" rows="10" placeholder='Message' required></textarea>
                        <div className="flex flex-col gap-10 justify-center items-center">
                            {/* <div data-netlify-recaptcha="true"></div> */}
                            <button type="submit" className='flex justify-center items-center gap-2 w-[100px] h-[50px] border-2 border-[#C897F3] text-[#9542f5] rounded-md p-2 font-bold hover:bg-[#C897F3] hover:text-white duration-300'>
                                Send
                                <IoSendSharp />
                            </button>
                        </div>
                    </form>
                </div>
                <div className="block border-dotted border-b-4 border-[#9542f5] mt-10 w-10" />
                <ul className='flex flex-row py-10 gap-5'>
                    <li><a href="https://github.com/chishingli" target="_blank" rel="noopener noreferrer"><FaGithub className="hover:fill-[#C897F3]" size={50} fill="white" /></a></li>
                    <li><a href="https://linkedin.com/in/chishingli" target="_blank" rel="noopener noreferrer"><FaLinkedin className="hover:fill-[#C897F3]" size={50} fill="white" /></a></li>
                    {/* <li><FaRegEnvelope className="hover:fill-[#C897F3]" size={50} fill="white" /></li> */}
                </ul>
            </div>
        </div>
    )
}

export default Contact