import React from 'react'

import CouponJars from "../assets/project/CouponJars.png"
import CouponJars2 from "../assets/project/CouponJars2.png"
import Covid1 from "../assets/project/Covid1.jpg"
import Covid2 from "../assets/project/Covid2.jpg"
import DejaVu from "../assets/project/DejaVu.png"
import DejaVu2 from "../assets/project/DejaVu2.png"
const Project = () => {
    return (
        <div id="projects" className="flex flex-col justify-center items-center p-4 h-full w-screen bg-[#1b1b1b] mt-20">
            <div className='flex flex-col items-center mx-8'>
                <h1 className="text-white text-5xl font-semibold">Projects</h1>
                <div className="block border-b-4 border-y-[#9542f5] mt-5 w-20" />
                <div className='flex flex-col justify-center items-cente gap-8 mt-8 w-full'>
                    {/* project card */}
                    <div className='grid justify-center items-center gap-5 w-full md:grid-cols-3'>
                        <img className='rounded-md h-[300px] w-[500px] hover:scale-105 duration-300 md:h-[450px] shadow-md shadow-white object-cover md:w-full' src={DejaVu} alt="Project: DejaVu" />
                        <img className='rounded-md h-[300px] w-[500px] hover:scale-105 duration-300 md:h-[450px] shadow-md shadow-white object-cover md:w-full' src={DejaVu2} alt="Project: DejaVu" />
                        <div className='flex flex-col justify-center items-center max-w-[450px]'>
                            <h1 className='text-[#9542f5] text-2xl font-bold'>DejaVu</h1>
                            <p className='text-white text-lg'>
                                DejaVu is a full-stack social networking web application, built using the MERN stack. DeJaVu allows users to communicate and interact with other users around the world by sharing their thoughts, ideas and opinions in the form of photo and text feed.
                            </p>
                            {/* tool badges */}
                            <div className='flex flex-wrap justify-start gap-3 text-black font-bold mt-2 w-full'>
                                <div className='bg-[#C897F3] rounded-md  px-2'>
                                    MongoDB
                                </div>
                                <div className='bg-[#C897F3] rounded-md px-2'>
                                    ExpressJS
                                </div>
                                <div className='bg-[#C897F3] rounded-md px-2'>
                                    ReactJS
                                </div>
                                <div className='bg-[#C897F3] rounded-md px-2'>
                                    NodeJS
                                </div>
                                <div className='bg-[#C897F3] rounded-md px-2'>
                                    Redux
                                </div>
                                <div className='bg-[#C897F3] rounded-md px-2'>
                                    HTML
                                </div>
                                <div className='bg-[#C897F3] rounded-md px-2'>
                                    CSS
                                </div>
                                <div className='bg-[#C897F3] rounded-md px-2'>
                                    Third-Party API
                                </div>
                            </div>
                            {/* buttons */}
                            <div className='flex gap-3 mt-4 items-center justify-center'>
                                <button className='border-2 border-[#C897F3] text-[#9542f5] rounded-md p-2 font-bold hover:bg-[#C897F3] hover:text-white duration-300'><a href="https://github.com/ChiShingLi/DejaVu" target="_blank" rel="noopener noreferrer">Source Code</a></button>
                                <button className='border-2 border-[#eb6788] text-[#eb6788] rounded-md p-2 font-bold hover:bg-[#eb6788] hover:text-white duration-300'><a href="https://dejavu.lichi.dev/" target="_blank" rel="noopener noreferrer">Live Demo</a></button>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center'>
                        <div className="border-b-2 border-y-[#C897F3] mt-5 w-10" />
                    </div>
                    <div className='grid justify-center items-center gap-5 w-full md:grid-cols-3'>
                        <img className='rounded-md h-[300px] w-[500px] hover:scale-105 duration-300 md:h-[450px] shadow-md shadow-white object-cover md:w-full' src={CouponJars} alt="Project: CouponJars" />
                        <img className='rounded-md h-[300px] w-[500px] hover:scale-105 duration-300 md:h-[450px] shadow-md shadow-white object-cover md:w-full' src={CouponJars2} alt="Project: CouponJars" />
                        <div className='flex flex-col justify-center items-center max-w-[450px]'>
                            <h1 className='text-[#9542f5] text-2xl font-bold'>CouponJars</h1>
                            <p className='text-white text-lg'>
                                CouponJars is a platform that allows users to submit and share their finds about coupon and deals to other users. Built using MERN stack.
                            </p>
                            {/* tool badges */}
                            <div className='flex flex-wrap justify-start gap-3 text-black font-bold mt-2 w-full'>
                                <div className='bg-[#C897F3] rounded-md  px-2'>
                                    MongoDB
                                </div>
                                <div className='bg-[#C897F3] rounded-md px-2'>
                                    ExpressJS
                                </div>
                                <div className='bg-[#C897F3] rounded-md px-2'>
                                    ReactJS
                                </div>
                                <div className='bg-[#C897F3] rounded-md px-2'>
                                    NodeJS
                                </div>
                                <div className='bg-[#C897F3] rounded-md px-2'>
                                    HTML
                                </div>
                                <div className='bg-[#C897F3] rounded-md px-2'>
                                    CSS
                                </div>
                            </div>
                            {/* buttons */}
                            <div className='flex gap-3 mt-4 items-center justify-center'>
                                <button className='border-2 border-[#C897F3] text-[#9542f5] rounded-md p-2 font-bold hover:bg-[#C897F3] hover:text-white duration-300'><a href="https://github.com/ChiShingLi/CouponJars" target="_blank" rel="noopener noreferrer">Source Code</a></button>
                                <button className='border-2 border-[#eb6788] text-[#eb6788] rounded-md p-2 font-bold hover:bg-[#eb6788] hover:text-white duration-300'><a href="https://couponjars.lichi.dev/" target="_blank" rel="noopener noreferrer">Live Demo</a></button>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center'>
                        <div className="border-b-2 border-y-[#C897F3] mt-5 w-10" />
                    </div>
                    <div className='grid justify-center items-center gap-5 w-full md:grid-cols-3'>
                        <img className='rounded-md h-[300px] w-[500px] hover:scale-105 duration-300 md:h-[450px] shadow-md shadow-white object-cover md:w-full' src={Covid1} alt="Project: Covid Tracker" />
                        <img className='rounded-md h-[300px] w-[500px] hover:scale-105 duration-300 md:h-[450px] shadow-md shadow-white object-cover md:w-full' src={Covid2} alt="Project: Covid Tracker" />
                        <div className='flex flex-col justify-center items-center max-w-[450px]'>
                            <h1 className='text-[#9542f5] text-2xl font-bold'>Coronavirus Data Tracker</h1>
                            <p className='text-white text-lg'>
                                Coronavirus Data Tracker is a simple web application that allows users to see the worldwide covid statistics by countries in an easy-to-navigate interactive world map, graph and table. Built using React, public APIs, and various Reactjs libraries.
                            </p>
                            {/* tool badges */}
                            <div className='flex flex-wrap justify-start gap-3 text-black font-bold mt-2 w-full'>
                                <div className='bg-[#C897F3] rounded-md px-2'>
                                    ReactJS
                                </div>
                                <div className='bg-[#C897F3] rounded-md px-2'>
                                    HTML
                                </div>
                                <div className='bg-[#C897F3] rounded-md px-2'>
                                    API
                                </div>
                            </div>
                            {/* buttons */}
                            <div className='flex gap-3 mt-4 items-center justify-center'>
                                <button className='border-2 border-[#C897F3] text-[#9542f5] rounded-md p-2 font-bold hover:bg-[#C897F3] hover:text-white duration-300'><a href="https://github.com/ChiShingLi/CovidApp" target="_blank" rel="noopener noreferrer">Source Code</a></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Project