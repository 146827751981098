import React from 'react'
import Logo from "../assets/logo.svg";

const Footer = () => {
    return (
        <div id="footer" className='flex flex-col justify-center items-center w-full h-full py-4 bg-[#1b1b1b]'>
            <div>
                <img className='w-[50px] py-4 md:w-[70px]' src={Logo} alt="CL Logo" />
            </div>
            <div className='text-gray-500 text-sm text-center'>
                <p>Designed &amp; built by Chi Li</p>
            </div>
        </div>
    )
}

export default Footer