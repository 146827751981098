import React from 'react'

const About = () => {
    return (
        <div id="about" className="flex flex-col items-center p-4 h-screen w-screen bg-[#1b1b1b]">
            <div className='flex flex-col justify-center items-center w-full h-full'>
                <h1 className="text-white text-5xl justify-self-start font-semibold">About</h1>
                <div className="block border-b-4 border-y-[#9542f5] mt-5 w-20" />
                <p className='text-white mt-10 p-5 text-lg max-w-3xl md:w-[50%]'>
                    Hi there! I'm a Software Engineer with a focus on the fullstack development.
                </p>
                <p className='text-white mt-2 p-5 text-lg max-w-3xl md:w-[50%]'>
                    In my free time, I enjoy cooking and keeping myself informed on the latest technologies. I also love building softwares and web applications and seeing the end-result of a product that meet customer needs.
                </p>
            </div>
        </div>
    )
}

export default About